import { mergeClasses } from '@expo/styleguide';
import { ReactNode, ComponentType, HTMLAttributes, PropsWithChildren } from 'react';

import { LABEL } from '~/ui/components/text';

type SnippetHeaderProps = PropsWithChildren<{
  title: string | ReactNode;
  Icon?: ComponentType<HTMLAttributes<SVGSVGElement>>;
  alwaysDark?: boolean;
  float?: boolean;
}>;

export function SnippetHeader({
  title,
  children,
  Icon,
  float,
  alwaysDark = false,
}: SnippetHeaderProps) {
  return (
    <div
      className={mergeClasses(
        'flex min-h-[40px] justify-between overflow-hidden border border-default bg-default pl-4',
        !float && 'rounded-t-md border-b-0',
        float && 'my-4 rounded-md',
        Icon && 'pl-3',
        alwaysDark && 'dark-theme border-transparent !bg-palette-gray3 pr-2'
      )}>
      <LABEL
        className={mergeClasses(
          'flex h-10 select-none items-center gap-2 truncate pr-4 font-medium !leading-10',
          alwaysDark && 'text-palette-white'
        )}>
        {Icon && <Icon className="icon-sm" />}
        {title}
      </LABEL>
      {!!children && <div className="flex items-center justify-end">{children}</div>}
    </div>
  );
}
